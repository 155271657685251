import { fetchAuthSession } from '@aws-amplify/auth';

const getJwtToken = async () => {
  try {
    //const { tokens } = await fetchAuthSession();
    //const { tokens } = await fetchAuthSession();
    //forceRefresh
    const { tokens } = await fetchAuthSession({ forceRefresh: true });

    const jwtToken = tokens.idToken.toString();
    const expirationTime = tokens.idToken.payload.exp;
    
    localStorage.setItem('jwtToken', jwtToken);
    localStorage.setItem('tokenExpiration', expirationTime);
    
    console.log('JWT Token stored in localStorage');
  } catch (error) {
    console.error('Error getting JWT token:', error);
  }
};

export const getValidToken = async () => {
  const storedToken = localStorage.getItem('jwtToken');
  const expirationTime = localStorage.getItem('tokenExpiration');
  
  if (!storedToken || !expirationTime) {
    await getJwtToken();
    return localStorage.getItem('jwtToken');
  }

  const currentTime = Math.floor(Date.now() / 1000);
  const timeUntilExpiration = expirationTime - currentTime;

  if (timeUntilExpiration < 300) {
    await getJwtToken();
  }

  return localStorage.getItem('jwtToken');
};