import React, { useState, useEffect } from 'react';
import { FaGithub, FaPlus, FaTrash, FaSearch } from 'react-icons/fa';
import axios from 'axios';
import { getValidToken } from '../utils/tokenManager';
import { Store } from 'react-notifications-component';

function GitHubTab({ agentId }) {
  const [loading, setLoading] = useState(true);
  const [githubStatus, setGithubStatus] = useState(null);
  const [repositories, setRepositories] = useState([]);
  const [showAddRepo, setShowAddRepo] = useState(false);
  const [availableRepos, setAvailableRepos] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    checkGitHubStatus();
  }, []);

  const checkGitHubStatus = async () => {
    try {
      const token = await getValidToken();
      const response = await axios.get(
        `${process.env.REACT_APP_USER_API_URL}/auth/github/status`,
        { 
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      setGithubStatus(response.data);
      if (response.data.installed) {
        fetchRepositories();
      }
    } catch (error) {
      console.error('Error checking GitHub status:', error);
      Store.addNotification({
        title: 'Error',
        message: 'Failed to check GitHub connection status',
        type: 'danger',
        container: 'top-right',
        dismiss: { duration: 3000 }
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchRepositories = async () => {
    try {
      const token = await getValidToken();
      const response = await axios.get(
        `${process.env.REACT_APP_USER_API_URL}/agents/${agentId}/github/repositories`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      setRepositories(response.data.repositories);
    } catch (error) {
      console.error('Error fetching repositories:', error);
    }
  };

  const handleAddRepository = async (repoName) => {
    try {
      const token = await getValidToken();
      await axios.post(
        `${process.env.REACT_APP_USER_API_URL}/agents/${agentId}/github/repositories`,
        { repository: repoName },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      fetchRepositories();
      setShowAddRepo(false);
      Store.addNotification({
        title: 'Success',
        message: 'Repository added successfully',
        type: 'success',
        container: 'top-right',
        dismiss: { duration: 3000 }
      });
    } catch (error) {
      console.error('Error adding repository:', error);
      Store.addNotification({
        title: 'Error',
        message: 'Failed to add repository',
        type: 'danger',
        container: 'top-right',
        dismiss: { duration: 3000 }
      });
    }
  };

  const handleRemoveRepository = async (repoName) => {
    try {
      const token = await getValidToken();
      await axios.delete(
        `${process.env.REACT_APP_USER_API_URL}/agents/${agentId}/github/repositories/${encodeURIComponent(repoName)}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      fetchRepositories();
      Store.addNotification({
        title: 'Success',
        message: 'Repository removed successfully',
        type: 'success',
        container: 'top-right',
        dismiss: { duration: 3000 }
      });
    } catch (error) {
      console.error('Error removing repository:', error);
      Store.addNotification({
        title: 'Error',
        message: 'Failed to remove repository',
        type: 'danger',
        container: 'top-right',
        dismiss: { duration: 3000 }
      });
    }
  };

  const fetchAvailableRepositories = async () => {
    try {
      const token = await getValidToken();
      const response = await axios.get(
        `${process.env.REACT_APP_USER_API_URL}/auth/github/repositories`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      setAvailableRepos(response.data.repositories);
    } catch (error) {
      console.error('Error fetching available repositories:', error);
      Store.addNotification({
        title: 'Error',
        message: 'Failed to fetch available repositories',
        type: 'danger',
        container: 'top-right',
        dismiss: { duration: 3000 }
      });
    }
  };

  const filteredRepos = availableRepos.filter(repo =>
    repo.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const renderAddRepoModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg max-w-2xl w-full">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">Add Repository</h3>
          <button
            onClick={() => setShowAddRepo(false)}
            className="text-gray-500 hover:text-gray-700"
          >
            ×
          </button>
        </div>

        <div className="relative mb-4">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <FaSearch className="text-gray-400" />
          </div>
          <input
            type="text"
            placeholder="Search repositories..."
            className="w-full pl-10 p-2 border rounded"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="max-h-96 overflow-y-auto">
          {filteredRepos.length === 0 ? (
            <p className="text-gray-500 text-center py-4">No repositories found</p>
          ) : (
            <div className="grid gap-2">
              {filteredRepos.map((repo) => (
                <button
                  key={repo.id}
                  onClick={() => handleAddRepository(repo.name)}
                  className="w-full text-left p-3 hover:bg-gray-50 rounded-lg border transition-colors"
                >
                  <div className="font-medium">{repo.name}</div>
                  {repo.description && (
                    <div className="text-sm text-gray-600">{repo.description}</div>
                  )}
                  <div className="text-xs text-gray-500 mt-1">
                    Last updated: {new Date(repo.updated_at).toLocaleDateString()}
                    {repo.private && (
                      <span className="ml-2 px-2 py-0.5 bg-gray-100 rounded-full">
                        Private
                      </span>
                    )}
                  </div>
                </button>
              ))}
            </div>
          )}
        </div>

        <div className="flex justify-end mt-4">
          <button
            onClick={() => setShowAddRepo(false)}
            className="px-4 py-2 text-gray-600 hover:text-gray-800"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    if (showAddRepo) {
      fetchAvailableRepositories();
    }
  }, [showAddRepo]);

  if (loading) {
    return <div className="animate-pulse">Loading...</div>;
  }

  if (!githubStatus?.installed) {
    return (
      <div className="text-center py-8">
        <FaGithub className="text-4xl mx-auto mb-4 text-gray-400" />
        <p className="text-gray-600 mb-4">
          GitHub integration is not connected. Please connect GitHub in the integrations page.
        </p>
        <a
          href="/integrations/github"
          className="inline-flex items-center px-4 py-2 bg-gray-900 text-white rounded hover:bg-gray-700 transition-colors"
        >
          <FaGithub className="mr-2" />
          Connect GitHub
        </a>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold">Connected Repositories</h2>
        <button
          onClick={() => setShowAddRepo(true)}
          className="flex items-center px-3 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
        >
          <FaPlus className="mr-2" />
          Add Repository
        </button>
      </div>

      {repositories.length === 0 ? (
        <p className="text-gray-600 text-center py-4">No repositories connected yet.</p>
      ) : (
        <div className="grid gap-4">
          {repositories.map((repo) => (
            <div
              key={repo.name}
              className="flex justify-between items-center p-4 bg-gray-50 rounded-lg"
            >
              <div>
                <h3 className="font-medium">{repo.name}</h3>
                <p className="text-sm text-gray-600">{repo.description}</p>
              </div>
              <button
                onClick={() => handleRemoveRepository(repo.name)}
                className="p-2 text-red-600 hover:text-red-800 transition-colors"
                title="Remove repository"
              >
                <FaTrash />
              </button>
            </div>
          ))}
        </div>
      )}

      {showAddRepo && renderAddRepoModal()}
    </div>
  );
}

export default GitHubTab; 