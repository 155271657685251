import React, { useState, useEffect } from 'react';
import { FaGithub } from 'react-icons/fa';
import axios from 'axios';
import { Store } from 'react-notifications-component';
import { getValidToken } from '../../utils/tokenManager';

function GitHub() {
  const [loading, setLoading] = useState(true);
  const [githubStatus, setGithubStatus] = useState(null);

  useEffect(() => {
    checkGitHubStatus();
  }, []);

  const checkGitHubStatus = async () => {
    try {
      const token = await getValidToken();
      const response = await axios.get(
        `${process.env.REACT_APP_USER_API_URL}/auth/github/status`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      setGithubStatus(response.data);
    } catch (error) {
      console.error('Error checking GitHub status:', error);
      Store.addNotification({
        title: 'Error',
        message: 'Failed to check GitHub connection status',
        type: 'danger',
        container: 'top-right',
        dismiss: { duration: 3000 }
      });
    } finally {
      setLoading(false);
    }
  };

  const handleConnect = async () => {
    try {
      const token = await getValidToken();
      const response = await axios.get(
        `${process.env.REACT_APP_USER_API_URL}/auth/github/installation-url`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      window.location.href = response.data.url;
    } catch (error) {
      console.error('Error getting installation URL:', error);
      Store.addNotification({
        title: 'Error',
        message: 'Failed to start GitHub connection',
        type: 'danger',
        container: 'top-right',
        dismiss: { duration: 3000 }
      });
    }
  };

  const handleDisconnect = async () => {
    try {
      const token = await getValidToken();
      await axios.delete(
        `${process.env.REACT_APP_USER_API_URL}/auth/github/disconnect`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      setGithubStatus({ installed: false, github: null });
      Store.addNotification({
        title: 'Success',
        message: 'GitHub disconnected successfully',
        type: 'success',
        container: 'top-right',
        dismiss: { duration: 3000 }
      });
    } catch (error) {
      console.error('Error disconnecting GitHub:', error);
      Store.addNotification({
        title: 'Error',
        message: 'Failed to disconnect GitHub',
        type: 'danger',
        container: 'top-right',
        dismiss: { duration: 3000 }
      });
    }
  };

  if (loading) {
    return (
      <div className="bg-white shadow rounded-lg p-6">
        <div className="animate-pulse">Loading...</div>
      </div>
    );
  }

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <div className="flex items-center mb-6">
        <FaGithub className="text-3xl mr-3" />
        <h1 className="text-3xl font-bold text-gray-800">GitHub Integration</h1>
      </div>
      
      <div className="mb-6">
        {githubStatus?.installed ? (
          <div>
            <div className="flex items-center mb-4">
              <span className="bg-green-100 text-green-800 px-2 py-1 rounded-full text-sm mr-3">Connected</span>
              <span className="text-gray-600">
                Connected since {new Date(githubStatus.github.connectedAt).toLocaleDateString()}
              </span>
            </div>
            <div className="flex space-x-4">
              <a
                href={`https://github.com/apps/zapdroid-dev/installations/${githubStatus.github.installationId}`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center px-4 py-2 bg-gray-100 text-gray-700 rounded hover:bg-gray-200 transition-colors"
              >
                <FaGithub className="mr-2" />
                Manage Installation
              </a>
              <button
                onClick={handleDisconnect}
                className="px-4 py-2 bg-red-100 text-red-700 rounded hover:bg-red-200 transition-colors"
              >
                Disconnect
              </button>
            </div>
          </div>
        ) : (
          <>
            <p className="text-gray-600 mb-4">
              Connect your GitHub account to enable automated workflows and repository management.
            </p>
            <button
              onClick={handleConnect}
              className="flex items-center px-4 py-2 bg-gray-900 text-white rounded hover:bg-gray-700 transition-colors"
            >
              <FaGithub className="mr-2" />
              Connect GitHub Account
            </button>
          </>
        )}
      </div>

      <div className="border-t pt-6">
        <h2 className="text-xl font-semibold mb-3">Features</h2>
        <ul className="list-disc list-inside text-gray-600">
          <li>Automated repository management</li>
          <li>Workflow automation</li>
          <li>Issue and PR tracking</li>
          <li>Organization access</li>
        </ul>
      </div>
    </div>
  );
}

export default GitHub; 